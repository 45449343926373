const ShimmerRestMenu = () => {
  return (
    <div className=" mt-4 animate-pulse">
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
      <div className="flex cursor-pointer justify-between items-center p-4 my-2 rounded-md select-none">
        <h3 className="text-lg font-semibold bg-gray-200 w-44 md:w-60 h-6 rounded-md"></h3>
        <button className="w-6 h-6 bg-gray-200 rounded-md"></button>
      </div>
    </div>
  );
};
export default ShimmerRestMenu;
